import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames';
import { useCheckbox } from './context' 

interface OptionProps {
  value: string
  className?: string
}

const Option: FC<PropsWithChildren<OptionProps>> = ({
  children,
  value,
  className,
}) => {
  const [ctx, onChange] = useCheckbox();
  return (
    <div className={classNames("checkbox-item", className)}>
      <label>
        <input
          onChange={(e) => onChange(value, e.target.checked)}
          className="checkbox-input"
          type="checkbox"
          value=""
        />
        <span
          className={classNames("checkbox-status", {
            checked: ctx?.includes(value),
          })}
        ></span>
        <span className="checkbox-text">{children}</span>
      </label>
    </div>
  );
};

export default Option;