import { Image, FadeTranstion } from '@/components'
import Growth1 from '@/assets/images/growth-1-mobile.png'
import Growth2 from "@/assets/images/growth-2.png";
import Growth3 from "@/assets/images/growth-3.png";
import './style.scss'
import "./style.mobile.scss"

const Growth = () => {
  return (
    <section className="growth">
      <FadeTranstion className="images-container">
        <Image src={Growth1} className="growth-1" />
        <Image src={Growth2} className="growth-2" />
        <Image src={Growth3} className="growth-3" />
      </FadeTranstion>
      <FadeTranstion className="description">
        <h4 className="description-title text-title">
          Accelerated Business Growth
        </h4>
        <p className="text-content">
          Fuel the expansion of your business. Our robust platform acts as a
          growth catalyst, providing you with the tools to scale your business
          rapidly, establishing a dominant presence in the global fashion
          market.
        </p>
      </FadeTranstion>
    </section>
  );
}

export default Growth