import { useNavigate } from 'react-router-dom'
import { Image, Button } from "@/components";
import { useMobile } from '@/hooks'
import "./style.scss"
import avatar from '@/assets/images/avatar.png'
import "./style.mobile.scss"

const Footer = () => {
  const [ isMobile ] = useMobile()
  const navigate = useNavigate();
  return (
    <footer className="footer-container">
      <div className="title">
        {isMobile ? (
          <>
            Cloud Store partners ecosystem empower the merchants and brands to
            sell globally in an very cost effective way.
          </>
        ) : (
          <>
            Cloud Store partners ecosystem empower <br />
            the merchants and brands to sell globally in an <br />
            very cost effective way.
          </>
        )}
      </div>
      <div className="card-list">
        <div className="card">
          <div className="card-content">
            “Cloud Store has outperformed our Tmall Store and Borderfree
            shipping into China month over month.”
          </div>
          <div className="person">
            <Image className="avatar" src={avatar}></Image>
            <div className="introduce">
              VP of international,
              <br />A Large Department Store
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-content">
            “Cloud Store has given us a safe alternative to engage the
            cross-border shoppers, not only the US market, but also from China,
            Korea and Australia.”
          </div>
          <div className="person">
            <Image className="avatar" src={avatar}></Image>
            <div className="introduce">
                VP of Omnichannel,<br />
                A Large US Apparel Brand
            </div>
          </div>
        </div>
      </div>
      <Button className="action-button" onClick={() => navigate("/contact-us")}>
        Contact Us
      </Button>
    </footer>
  );
}

export default Footer;