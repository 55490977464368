import { useState } from "react";
import CountUp from "react-countup";
import classnames from 'classnames'
import { Parallax } from 'react-scroll-parallax'
import './style.scss'
import "./style.mobile.scss"

const dataList = [
  {
    label: "Brands",
    value: "6000",
    count: 6000,
  },
  {
    label: "GMV generated",
    value: "1,000,000,000",
    count: 1000000000,
    unit: "$"
  },
  {
    label: "Orders fulfilled",
    value: "5,000,000",
    count: 5000000,
  },
];

const Data = () => {
  const [inViewport, setInViewport] = useState(false);
  return (
    <div className="data">
      <Parallax 
        className="data-main"
        onEnter={() => setInViewport(true)}
        onExit={() => setInViewport(false)}
      >
        {dataList.map((item, index) => (
          <li
            key={item.label}
            className={classnames("data-item", `data-${index}`)}
          >
            <div className={classnames("data-value", `item-${index}`)}>
              <span>
                {item.unit}
                {inViewport ? (
                  <CountUp start={0} end={item.count} duration={1} />
                ) : (
                  item.value
                )}
                +
              </span>
            </div>
            <div className="data-label text-content">{item.label}</div>
          </li>
        ))}
      </Parallax>
    </div>
  );
}

export default Data;