import { FC, useState, CSSProperties, useRef, MouseEvent, ReactNode } from "react";
import classNames from "classnames"
import arrowDown from '@/assets/images/arrow-down.png'
import { Image } from '@/components'
import { useClickAway  } from '@/hooks'
import "./style.scss";
import "./style.mobile.scss"

interface SelectProps {
  className?: string;
  style?: CSSProperties;
  label?: ReactNode;
  value?: any;
  onChange?: (e: any) => void;
  options: {label: string, value: any}[]
  error?: boolean
}
;

const Select: FC<SelectProps> = ({
  label,
  value,
  onChange,
  className,
  style,
  options,
  error,
}) => {
  const [focus, setFocus] = useState(false);
  const el = useRef<HTMLDivElement>(null);
  useClickAway(el, () => {
    setFocus(false)
  });
  const handleSelect = (
    value: string,
    e: MouseEvent
  ) => {
    e.stopPropagation();
    onChange?.(value);
    setFocus(false);
  };
  const getCurrentLabel = () => {
    return options?.find((item) => item.value === value)?.label;
  };

  return (
    <div
      ref={el}
      className={classNames("select-container", className, {
        fill: value,
        focus,
        error,
      })}
      style={style}
      onClick={() => setFocus(true)}
    >
      <div className="select-content">
        <div className="placeholder">{label}</div>
        <div className="select-inner">
          <span>{getCurrentLabel()}</span>
          <Image className="arrow-down" src={arrowDown}></Image>
        </div>
      </div>
      <span className="border-line"></span>
      <ul className={classNames("select-options", { open: focus })}>
        {options.map((item) => (
          <li
            key={item.value}
            onClick={(e) => handleSelect(item.value, e)}
            className={classNames("select-option", {
              active: value === item.value,
            })}
          >
            <div className="select-option-inner">
              <span>{item.label}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Select;