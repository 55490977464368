import { FC, PropsWithChildren, CSSProperties, useState } from "react";
import classnames from "classnames";
import { Parallax } from 'react-scroll-parallax'
import './style.scss'

interface FadeTranstionProps {
  className?: string;
  style?: CSSProperties;
  duration?: string;
  delay?: string
}

const FadeTranstion: FC<PropsWithChildren<FadeTranstionProps>> = ({
  duration,
  className,
  style,
  children,
  delay,
}) => {
  const [inViewport, setInViewport] = useState(false);
  return (
    <Parallax
      className={classnames(className, "fade-transtion")}
      style={style}
      onEnter={() => setInViewport(true)}
      onExit={() => setInViewport(false)}
    >
       <div
        style={{ transitionDuration: duration, transitionDelay: delay }}
        className={classnames("fade-transtion-content", {
          // fadeOutUp: !inViewport,
          fadeInUp: inViewport,
        })}
      >
        {children}
      </div>
    </Parallax>
  );
};

FadeTranstion.defaultProps = {
  duration: "1s"
}

export default FadeTranstion;