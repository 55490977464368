import { Parallax } from "react-scroll-parallax";
import { TextLine, Image } from "@/components";
import { useMobile } from '@/hooks'
import BY_S_PD from "@/assets/images/by-s-pd.png";
import BY_PL from "@/assets/images/by-pl.png";
import PD_P_H from "@/assets/images/pd-p-h.png";
import BY_HOT_LIST from "@/assets/images/by-hot-list.png";
import BY_HOT_LIST_MOBOILE from "@/assets/images/by-hot-list-mobile.png";
import CART from "@/assets/images/cart.png";
import CART_MOBOILE from "@/assets/images/cart-mobile.png";
import BEAUTY from "@/assets/images/beauty.png";
import BEAUTY_MOBOILE from "@/assets/images/beauty-mobile.png";
import BY_S_H from "@/assets/images/by-s-h.png";
import BY_S_H_MOBOILE from "@/assets/images/by-s-h-mobile.png";
import SHOW from "@/assets/images/show.png";
import BY_H from "@/assets/images/by-h.png";
import "./style.scss";
import "./style.mobile.scss"

const Gallery = () => {
  const [ isMobile ] = useMobile();
  return (
    <section className="gallery">
      <div className="gallery-content">
        <h4 className="gallery-title">
          <div className="gallery-title-content">
            {isMobile ? (
              <>
                Welcome to Cloud Store <br />
                Create your <br /> shoppable global <br /> fashion business{" "}
                <br /> by API calls
              </>
            ) : (
              <>
                <div>
                  Welcome to&nbsp;
                  <TextLine lineClass="cloud-store-line">Cloud Store</TextLine>
                </div>
                <div>Create your shoppable global</div>
                <div>fashion business by API calls</div>
              </>
            )}
          </div>
          {!isMobile && <span className="point"></span>}
        </h4>
        {!isMobile && (
          <>
            <div className="col col-1">
              <Parallax className="col-transform" speed={16}>
                <Image className="by-pl image" src={BY_PL}></Image>
                <Image className="pd-p-h image" src={PD_P_H}></Image>
              </Parallax>
            </div>
            <div className="col col-5">
              <Parallax className="col-transform" speed={10}>
                <Image className="show image" src={SHOW}></Image>
                <Image className="by-h image" src={BY_H}></Image>
              </Parallax>
            </div>
          </>
        )}
      </div>
      <div className="col col-2">
        <Parallax className="col-transform" speed={isMobile ? 5 : 10}>
          <Image
            className="by-hot-list image"
            src={isMobile ? BY_HOT_LIST_MOBOILE : BY_HOT_LIST}
          ></Image>
          <Image
            className="cart image"
            src={isMobile ? CART_MOBOILE : CART}
          ></Image>
        </Parallax>
      </div>
      <div className="col col-3">
        <Parallax className="col-transform" speed={isMobile ? -5 : -10}>
          <Image className="by-s-pd image" src={BY_S_PD}></Image>
        </Parallax>
      </div>
      <div className="col col-4">
        <Parallax className="col-transform" speed={isMobile ? 8 : 16}>
          <Image
            className="beauty image"
            src={isMobile ? BEAUTY_MOBOILE : BEAUTY}
          ></Image>
          <Image
            className="by-s-h image"
            src={isMobile ? BY_S_H_MOBOILE : BY_S_H}
          ></Image>
        </Parallax>
      </div>
    </section>
  );
};

export default Gallery;
