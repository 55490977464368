import { Image, FadeTranstion } from '@/components'
import discover from "@/assets/images/by-discover.png";
import './style.scss'
import "./style.mobile.scss"

const Fashion = () => {
  return (
    <section className="fashion">
      <FadeTranstion className="description">
        <h4 className="description-title text-title">
          Global Fashion at Your Fingertips
        </h4>
        <p className="text-content">
          Expand your global fashion business effortlessly through our API. Our
          platform empowers you to curate an immersive and engaging list of
          products, brands and SKUs, turning aspirations into reality for your
          clientele across the world.
        </p>
      </FadeTranstion>
      <FadeTranstion>
        <Image src={discover} className="discover"></Image>
      </FadeTranstion>
    </section>
  );
}

export default Fashion;
