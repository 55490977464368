import "./style.scss";
import "./style.mobile.scss";
import _Checkbox from "./checkbox";
import Option from "./option";

const Checkbox = Object.assign(_Checkbox, { Option });

export default Checkbox;

export { Checkbox };
