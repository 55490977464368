import './style.scss'
import "./style.mobile.scss"
import { Image, FadeTranstion } from '@/components'
import AccessibilityImg from "@/assets/images/accessibility-mobile.png";

const Accessibility = () => {
  return (
    <section className="accessibility">
      <FadeTranstion className="description">
        <h4 className="description-title text-title">
          Unrivaled Global Supply Accessibility
        </h4>
        <p className="text-content">
          Easily access the global supply through seamless API integration. Our
          platform empowers you to effortlessly connect with the most coveted
          suppliers, brands and SKUs, ensuring near real time accurate product
          and deal information that sets you apart from the competition.
        </p>
      </FadeTranstion>
      <FadeTranstion className="accessibility-img-container">
        <Image className="accessibility-img" src={AccessibilityImg}></Image>
      </FadeTranstion>
    </section>
  );
}

export default Accessibility;