import { useNavigate } from 'react-router-dom'
import { Image, FadeTranstion, Button } from "@/components";
import { useMobile } from '@/hooks'
import GlobalSalesImage from "@/assets/images/global-sales-mobile.png";
import "./style.scss";
import "./style.mobile.scss"

const GlobalSales = () => {
  const [ isMobile ] = useMobile()
  const navigate = useNavigate();
  return (
    <section className="global-sales">
      <FadeTranstion className="global-sales-image-wrapper">
        <Image src={GlobalSalesImage} className="global-sales-image"></Image>
      </FadeTranstion>
      <FadeTranstion className="description">
        <h4 className="description-title text-title">
          Effortless Global Sales
        </h4>
        <p className="text-content">
          Experience the power of simplicity in selling global luxury fashion.
          Our platform provides you with the easiest and most effective tools,
          allowing you to focus on what you do best – delivering an unparalleled
          luxury experience to your customers.
        </p>
        {!isMobile && (
          <Button onClick={() => navigate("/contact-us")}>Contact Us</Button>
        )}
      </FadeTranstion>
      {isMobile && (
        <Button onClick={() => navigate("/contact-us")}>Contact Us</Button>
      )}
    </section>
  );
};

export default GlobalSales;
