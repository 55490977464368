export function scrollIntoView(el: HTMLElement) {
  if(!el) {
    return
  }
  if(typeof el.scrollIntoView === "function") {
    return el.scrollIntoView()
  }
  const top = el?.getBoundingClientRect?.()?.top || 0;
  window.scrollTo(
    0,
    top +
      (window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop)
  );
}