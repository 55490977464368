import './style.scss'
import "./style.mobile.scss"
import { useMobile } from '@/hooks'
import classNames from 'classnames';

const Advantage = () => {

  const [isMobile] = useMobile();

  return (
    <section className="advantage">
      <div className="advantage-main">
        <h4 className="advantage-title c-w t-a-c">
          Why choose our {isMobile && <br />}Cloud Store?
        </h4>
        <div className="advantage-module advantage-differentiator">
          <p className="title">
            Differentiator –The Symphony of Simplicity and Exclusivity
          </p>
          <p className="content">
            What sets Cloud Store apart is its harmonious blend of simplicity
            and exclusivity. Our API enables end-to-end eCommerce integration.
            Its ready-to-use network of merchants and brands provides a gateway
            to the vast world of luxury commerce. Seamlessly connect with many
            of the world's most prestigious brands, merchants, and products to
            grow your business exponentially and create a shopping experience
            that aligns with the sophistication of your clientele. We don't just
            offer a product; we help you craft a journey to the pinnacle of
            luxury commerce.
          </p>
        </div>
        <div className="row orders-gmv flex mt-10">
          <div className="col orders advantage-module">
            <div className="col-value c-w">5,000,000+</div>
            <div className="col-label c-w">Successful orders</div>
          </div>
          <div
            className={classNames("col gmv advantage-module", {
              "flex-1": !isMobile,
            })}
          >
            <div className="col-value">$1,000,000,000+</div>
            <div className="col-label">
              GMV generated through cloud store {isMobile && <br />}technology.
            </div>
          </div>
        </div>
        <div className="row solutions-communication-team flex mt-10">
          <div className="col solutions advantage-module">
            <div className="title">Flexible Solutions</div>
            <div className="content">
              We offered flexible solution from catalog API to logistic options.
            </div>
          </div>
          <div className="col communication flex-1 advantage-module">
            <div className="title c-w">Direct communication with merchants</div>
            <div className="content c-w">
              We provided the exclusive deals and discounts, release date
              calendars for hot SKUs and limited-edition items by direct
              collaboration with merchants.
            </div>
          </div>
          <div className="col team advantage-module">
            <div className="title c-w">Professional team</div>
            <div className="content c-w">
              Our top-tiered engineering team is led by experienced Google
              engineers in Silicon Valley. We provide stable and professional
              technical support.
            </div>
          </div>
        </div>

        <div className="row services flex mt-10">
          <div className="col service advantage-module flex-1">
            <div className="title">Satisfactory customer service</div>
            <div className="content">
              Our existing clients are from US, China, South Korea, Australia,
              Japan and South America.
            </div>
          </div>
          {!isMobile && (
            <>
              <div className="col country country-1  advantage-module">
                <div className="country-item usa c-w">
                  <span>USA</span>
                </div>
                <div className="country-item china c-w">
                  <span>China</span>
                </div>
              </div>
              <div className="col country country-2  advantage-module">
                <div className="country-item korean">
                  <span>Korean</span>
                </div>
                <div className="country-item australia c-w">
                  <span>Australia</span>
                </div>
                <div className="country-item japan c-w">
                  <span>Japan</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Advantage;