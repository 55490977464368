import { RefObject, useEffect, useRef } from "react";


const useClickAway = (
  ref: RefObject<HTMLElement | null>,
  onClickAway: (event: Event) => void,
) => {
  const savedCallback = useRef(onClickAway);
  savedCallback.current = onClickAway;

  useEffect(() => {
    const handler = (event: Event) => {
      const { current: el } = ref;
      el && !el.contains(event.target as Node) && savedCallback.current(event);
    };
    document.addEventListener("click", handler)
    return () => {
      document.removeEventListener("click", handler);
    };
  }, [ref]);
};

export default useClickAway;

