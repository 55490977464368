import { useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import emitter from "@/event";
import { TextLine, Button } from "@/components";
import { scrollIntoView } from "@/utils";
import { useMobile } from "@/hooks";
import "./style.scss";
import "./style.mobile.scss";
import brandLaunchIcon from "@/assets/images/brand-launch-icon.png";
import robot from "@/assets/images/robot.png"
import googleIcon from '@/assets/images/google-icon.png'
import walmartIcon from "@/assets/images/walmart-icon.png";
import pinterestIcon from "@/assets/images/pinterest-icon.png";
import tiktokIcon from "@/assets/images/tiktok-icon.png";
import sheinIcon from "@/assets/images/shein-icon.png";
import beyondstyleIcon from "@/assets/images/beyondstyle-icon.png";


const logoList = [
  {
    url: googleIcon,
    label: "google",
  },
  {
    url: walmartIcon,
    label: "walmart",
  },
  {
    url: pinterestIcon,
    label: "pinterest",
  },
  {
    url: tiktokIcon,
    label: "tiktok",
  },
  {
    url: sheinIcon,
    label: "shein",
  },
  {
    url: beyondstyleIcon,
    label: "beyondstyle"
  },
];

const BrandLaunch = () => {
  const el = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile] = useMobile();

   useEffect(() => {
     const scrollHandler = () => {
       scrollIntoView(el.current as HTMLDivElement);
     };
     emitter.on("SCROLL_TO_BRAND_LAUNCH", scrollHandler);
     return () => {
       emitter.on("SCROLL_TO_BRAND_LAUNCH", scrollHandler);
     };
   }, []);

   useEffect(() => {
     if (location.pathname === "/brand-launch") {
       setTimeout(() => {
         scrollIntoView(el.current as HTMLDivElement);
       }, 20);
     }
   }, [location.pathname]);
  return (
    <div className="brand-launch" ref={el}>
      <div className="brand-launch-header">
        <img src={brandLaunchIcon} alt="" />
        <div className="brand-launch-info">
          <div className="brand-launch-title">BrandLaunch</div>
          <div className="brand-launch-description">
            BrandLaunch empowers {isMobile && <br />}
            <TextLine>worldwide brands</TextLine> to effectively access American
            consumers. Our service utilizes cutting-edge technology to establish
            brand presence on at least three top sales channels in the USA,
            including:
          </div>
        </div>
      </div>
      <ul className="logo-list">
        {logoList.map((item) => (
          <li key={item.label} className="logo-item">
            <img src={item.url} alt={item.label} className={item.label} />
          </li>
        ))}
      </ul>
      <p className="introduce">
        Through this comprehensive service and advanced AIGC (AI-Generated
        Content) technology, global brands will gain significant exposure,
        engagement, and sales opportunities in the American market.
      </p>
      <div className="infomation">
        <div className="des">
          <span>
            BrandLaunch is designed to maximize your brand's potential in the
            USA, leveraging the power of multiple platforms and AI-driven
            strategies to boost your visibility and sales.
          </span>
          <Button onClick={() => navigate(`/contact-us?type=BrandsMerchants`)}>
            Contact Us
          </Button>
        </div>
        <img className="img-robot" src={robot} alt="" />
      </div>
    </div>
  );
};

export default BrandLaunch;
