import { ScrollProvider, Header } from '@/components'
import { ParallaxProvider } from "react-scroll-parallax";
import { Toaster } from "react-hot-toast";
import Router from '@/router'

function App() {
  return (
    <ParallaxProvider>
      <ScrollProvider>
        <Header />
        <Router />
        <Toaster />
      </ScrollProvider>
    </ParallaxProvider>
  );
}

export default App;
