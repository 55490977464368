import { Image, TextLine } from '@/components'
import { Parallax } from "react-scroll-parallax";
import { useMobile } from '@/hooks'
import { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom'
import { scrollIntoView } from "@/utils";
import marketplace11 from "@/assets/images/marketplace-1-1.png";
import marketplace12 from "@/assets/images/marketplace-1-2.png";
import marketplace13 from "@/assets/images/marketplace-1-3.png";
import marketplace14 from "@/assets/images/marketplace-1-4.png";
import marketplace15 from "@/assets/images/marketplace-1-5.png";

import marketplace21 from "@/assets/images/marketplace-2-1.png";
import marketplace22 from "@/assets/images/marketplace-2-2.png";
import marketplace23 from "@/assets/images/marketplace-2-3.png";
import marketplace24 from "@/assets/images/marketplace-2-4.png";

import marketplace31 from "@/assets/images/marketplace-3-1.png";
import marketplace32 from "@/assets/images/marketplace-3-2.png";
import marketplace33 from "@/assets/images/marketplace-3-3.png";
import marketplace34 from "@/assets/images/marketplace-3-4.png";
import marketplace35 from "@/assets/images/marketplace-3-5.png";


import checkoutDetail from '@/assets/images/checkout-detail.png'
import checkoutPage from '@/assets/images/checkout-page.png'

import endToEnd from "@/assets/images/end-to-end.png";
import signed from "@/assets/images/signed.png";

import macy_s from "@/assets/images/merchant-macy's.png";
import kors from "@/assets/images/kors.png";

import "./style.scss"
import "./style.mobile.scss"
import emitter from '@/event';

const list = [
  {
    list: [
      marketplace11,
      marketplace12,
      marketplace13,
      marketplace14,
      marketplace15,
    ],
    speed: 6,
  },
  {
    list: [marketplace21, marketplace22, marketplace23, marketplace24],
    speed: -8,
  },
  {
    list: [
      marketplace31,
      marketplace32,
      marketplace33,
      marketplace34,
      marketplace35,
    ],
    speed: 10
  },
];

const Marketplace = () => {
  const [isMobile] = useMobile()
  const el = useRef<HTMLElement>(null);
  const location = useLocation();
  useEffect(() => {
    const scrollHandler = () => {
      scrollIntoView(el.current as HTMLDivElement);
    };
    emitter.on("SCROLL_TO_E_COMMERCE_MARKETPLACE", scrollHandler);
    return () => {
      emitter.on("SCROLL_TO_E_COMMERCE_MARKETPLACE", scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/e-commerce-marketplace") {
      setTimeout(() => {
        scrollIntoView(el.current as HTMLDivElement);
      }, 20);
    }
  }, [location.pathname]);

  return (
    <section className="marketplace-container" ref={el}>
      {isMobile ? (
        <p className="title">
          If you are E-commerce <br /> Marketplace
        </p>
      ) : (
        <p className="title">
          If you are <br />
          <TextLine>E-commerce Marketplace</TextLine>
        </p>
      )}
      {!isMobile && (
        <p className="subtitle">Expand global fashion supply instantly.</p>
      )}
      <div className="imgs-container">
        {list.map((row, key) => (
          <div className="row" key={key}>
            <Parallax className="row-group" translateX={[0, row.speed]}>
              {row.list.map((item, index) => (
                <Image key={index} src={item} />
              ))}
            </Parallax>
          </div>
        ))}
      </div>
      {isMobile && (
        <p className="subtitle">Expand global fashion supply instantly.</p>
      )}
      <div className="description-block checkout">
        <Image className="checkout-page" src={checkoutPage}></Image>
        <Image className="checkout-detail" src={checkoutDetail}></Image>
        <div>
          {isMobile ? (
            <>
              Increase your revenue through <br />
              Cloud Store APIs integration <br />
              from catalog to universal <br />
              checkout.
            </>
          ) : (
            <>
              Increase your revenue <br />
              through Cloud Store <br />
              APIs integration <br />
              from catalog to <br />
              universal checkout.
            </>
          )}
        </div>
      </div>
      <div className="description-block end">
        <p>
          End-to-end cross-border logistics solution including first mile,
          warehouse network, freight forwarding, customs clearance and last mile
          delivery.
        </p>
        <Image className="end-2-end" src={endToEnd}></Image>
        <Image className="signed" src={signed}></Image>
      </div>
      <div className="description-block merchants">
        <Image className="macy_s" src={macy_s}></Image>
        {isMobile && (
          <div className="merchants-content">
            Convert your users directly in your app or site ordering from 400+
            global merchants and brands partners like Macy’s, Zappos, Saks Fifth
            Avenue, Harrods, <br />
            Michael Kors etc.
          </div>
        )}
        <Image className="kors" src={kors}></Image>
        {isMobile && (
          <div className="merchants-content">
            Enhance your brands and content strategy with global famous partners
            and brands.
          </div>
        )}
        {!isMobile && (
          <div className="merchants-content">
            <div>
              Convert your users directly in your app or site ordering from 400+
              global merchants and brands partners like Macy’s, Zappos, Saks
              Fifth Avenue, Harrods, <br />
              Michael Kors etc.
            </div>
            <div>
              Enhance your brands and content strategy with global famous
              partners and brands.
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Marketplace