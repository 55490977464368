/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from "react";
import { ScrollHandler, useScroll, getSrollEvent } from "@/components";

export default function useScrollHandler(handler: ScrollHandler) {
  const handlerRef = useRef<ScrollHandler>((e) => {
    handler?.(e);
  });
  const { scrollHandlers } = useScroll();
  useEffect(() => {
    handlerRef.current?.(getSrollEvent("init"));
    scrollHandlers.current?.push(handlerRef.current);
    return () => {
      let index = scrollHandlers.current?.findIndex(
        (hander) => hander === handlerRef.current
      );
      if (typeof index === "number" && index > -1) {
        scrollHandlers.current?.splice(index, 1);
      }
    };
  }, []);
}