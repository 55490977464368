import { FC, PropsWithChildren, ReactNode } from 'react'
import { useTabs } from './context'
export interface TabPaneProps {
  tab: string
  title: ReactNode
}

const TabPane: FC<PropsWithChildren<TabPaneProps>> = ({ children, tab }) => {
  const current = useTabs();
  return current === tab ? (
    <div className="tab-pane-item">{children}</div>
  ) : null;
};

export default TabPane;