import { FC, PropsWithChildren, useCallback, useRef, useMemo } from "react";
import classNames from "classnames";
import { CheckboxContext, ICheckboxContext } from "./context";
import "./style.scss";
import "./style.mobile.scss";

interface CheckboxProps {
  value: string[];
  onChange?: (e: string[]) => void;
  label?: string;
  className?: string;
  error?: boolean;
}
const Checkbox: FC<PropsWithChildren<CheckboxProps>> = ({
  value,
  onChange,
  label,
  className,
  error,
  children
}) => {

  const handler = useRef(onChange);
  handler.current = onChange

  const latestValue = useRef(value)
  latestValue.current = value

  const onUpdate = useCallback((field: string,  checked: boolean) => {
    if (checked) {
      if (latestValue.current.includes(field)) {
        return;
      }
      handler.current?.([...latestValue.current, field]);
    } else {
      handler.current?.(
        latestValue.current?.filter((item) => item !== field) || []
      );
    }
  }, []);

  const data: ICheckboxContext = useMemo(
    () => [value, onUpdate],
    [value, onUpdate]
  );

  return (
    <CheckboxContext.Provider value={data}>
      <div className={classNames("checkbox-container", className, { error })}>
        <div className="checkbox-label">{label}</div>
        <div className="checkbox-group">{children}</div>
      </div>
    </CheckboxContext.Provider>
  );
};

export default Checkbox;
