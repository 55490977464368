import { PropsWithChildren, FC, useState, CSSProperties } from "react";
import { Parallax } from 'react-scroll-parallax'
import classnames from "classnames";
import './style.scss'
import "./style.mobile.scss"

interface TextLineProps {
  className?: string;
  lineClass?: string;
  style?: CSSProperties;
  href?: string;
}

const TextLine: FC<PropsWithChildren<TextLineProps>> = ({
  children,
  className,
  lineClass,
  href,
}) => {
  const [inViewport, setInViewport] = useState(false);

  return (
    <Parallax
      className={classnames("text-line", className, { link: !!href })}
      onEnter={() => setInViewport(true)}
      onExit={() => setInViewport(false)}
      onClick={() => href && window.open(href)}
    >
      {children}
      <span
        className={classnames("line", lineClass, { "line-active": inViewport })}
      />
    </Parallax>
  );
};

export default TextLine;