import { Image, Button } from '@/components'
import { useNavigate } from "react-router-dom";
import catalog from "@/assets/images/catalog.png";
import checkout from "@/assets/images/checkout.png";
import logistics from "@/assets/images/logistics.png";
import './style.scss'
import "./style.mobile.scss"

const list = [
  {
    image: catalog,
    title: "Catalog API",
    content:
      "Provides a catalog of 20M+ SKU of fashion, beauty and luxury products from European and US merchants, with product information, pricing and inventory refreshed daily.",
  },
  {
    image: checkout,
    title: "Checkout API",
    content:
      "Universal checkout on your site for 400+ European and US merchants.",
  },
  {
    image: logistics,
    title: "Logistics API",
    content:
      "Tracking your customer packages end to end from merchants to delivery.",
  },
];

const API = () => {
  const navigate = useNavigate();
  return (
    <div className="api-container">
      <ul className="api-list">
        {list.map((item) => (
          <li className="api-item" key={item.title}>
            <Image className="api-item-img" src={item.image}></Image>
            <h4 className="text-title">{item.title}</h4>
            <p className="text-content">{item.content}</p>
          </li>
        ))}
      </ul>
      <Button onClick={() => navigate("/contact-us")}>Contact Us</Button>
    </div>
  );
}

export default API;