import { Image, FadeTranstion } from '@/components'
import bestValue from '@/assets/images/best-value-mobile.png'
import shoePrice from '@/assets/images/shoe-price.png'
import './style.scss'
import "./style.mobile.scss"

const APIIntegration = () => {

  return (
    <section className="api-integration">
      <FadeTranstion className="image-wrapper">
        <Image className="best-value img" src={bestValue}></Image>
        <Image className="shoe-price img" src={shoePrice}></Image>
      </FadeTranstion>
      <FadeTranstion className="description">
        <h4 className="description-title text-title">
          Flexible API integration: from Display to Checkout
        </h4>
        <p className="text-content">
          Witness a transformative journey as our API integration takes you from
          mere display ability to a seamless checkout experience. Maximize sales
          potential by eliminating friction points.
        </p>
      </FadeTranstion>
    </section>
  );
};

export default APIIntegration;
