import { FC, ButtonHTMLAttributes } from "react";
import Image from '../image'
import classNames from "classnames";
import arrow from '@/assets/images/arrow.png'
import './style.scss'
import "./style.mobile.scss"

const Button: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & { icon?: boolean }
> = ({ icon, className, children, ...props }) => {
  return (
    <button className={classNames("btn", className)} {...props}>
      <span>{children}</span>
      {icon && <Image src={arrow}></Image>}
    </button>
  );
};

Button.defaultProps = {
  icon: true
}

export default Button;