import { Image, TextLine } from "@/components"
import byLogo from '@/assets/images/by-logo.png'
import home from "@/assets/images/case-home.png";
import merchant from "@/assets/images/case-merchant.png";
import hot from "@/assets/images/case-hot.png";


import tmLogo from "@/assets/images/tm-logo.png";  
import product from "@/assets/images/tm-product.png";  
import store from "@/assets/images/tm-store.png";  

import "./style.scss"
import "./style.mobile.scss"

const SuccessfulCase = () => {
  return (
    <div className="successful-case">
      <div className="block">
        <div className="row">
          <Image className="logo" src={byLogo}></Image>
          <div className="title-content">
            <p className="title">Successful case</p>
            <div className="subtitle">
              <TextLine href="https://baleen-cdn-g.bybieyang.com/static/html/appEntry/index.html">
                Beyond App
              </TextLine>{" "}
              is one of the products using Cloud Store to serve Chinese
              consumers, with over 20 million users registrations. Cloud Store
              enables the entire stack of cross-border shopping process. Over
              1,000,000 orders are delivered from US and EU to Chinese users
              every year.
            </div>
          </div>
        </div>
        <div className="row merchant">
          <Image src={home}></Image>
          <Image src={merchant}></Image>
          <Image src={hot}></Image>
        </div>
      </div>
      <div className="tm">
        <Image src={tmLogo} className="tm-logo"></Image>
        <p className="title">Successful case</p>
        <div className="subtitle">
          <TextLine>Tmall Global</TextLine> uses Cloud Store to expand its long
          tail fashion supply from US. Over 8M SKU have been added in Tmall
          Global marketplace. It enables Tmall users to access the latest
          fashion trendy product from US market.
        </div>
        <Image className="image product" src={product}></Image>
        <Image className="image store" src={store}></Image>
      </div>
    </div>
  );
}

export default SuccessfulCase;