import { useNavigate } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { Parallax, ParallaxProps } from "react-scroll-parallax";
import { Image, TextLine, Button } from '@/components'
import { useLocation } from 'react-router-dom'
import { useMobile } from "@/hooks";
import emitter from '@/event';
import { scrollIntoView } from '@/utils'
import Logo from '@/assets/images/bys-logo.png'
import blackFriday from "@/assets/images/black-friday.png"
import blackFridayMobile from "@/assets/images/black-friday-mobile.png"
import dropShipping from "@/assets/images/drop-shipping.png"

import productRow11 from "@/assets/images/product-row-1-1.png"
import productRow12 from "@/assets/images/product-row-1-2.png"
import productRow13 from "@/assets/images/product-row-1-3.png"
import productRow14 from "@/assets/images/product-row-1-4.png"
import productRow15 from "@/assets/images/product-row-1-5.png"
import productRow16 from "@/assets/images/product-row-1-6.png"

import productRow21 from "@/assets/images/product-row-2-1.png";
import productRow22 from "@/assets/images/product-row-2-2.png";
import productRow23 from "@/assets/images/product-row-2-3.png";
import productRow24 from "@/assets/images/product-row-2-4.png";
import productRow25 from "@/assets/images/product-row-2-5.png";
import productRow26 from "@/assets/images/product-row-2-6.png";

import topFashion from "@/assets/images/top-fashion.png"
import betterDropShipping from "@/assets/images/better-drop-shipping.png"
import sales from "@/assets/images/sales.png";
import "./style.scss";
import "./style.mobile.scss"

const list = [
  {
    list: [
      productRow11,
      productRow12,
      productRow13,
      productRow14,
      productRow15,
      productRow16,
    ],
    translateX: [0, 8] as ParallaxProps["translateY"],
  },
  {
    list: [
      productRow21,
      productRow22,
      productRow23,
      productRow24,
      productRow25,
      productRow26,
    ],
    translateX: [0, -10] as ParallaxProps["translateY"],
  },
];

const BeyondStyle = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile] = useMobile();

  const el = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollHandler = () => {
      scrollIntoView(el.current as HTMLDivElement);
    };
    emitter.on("SCROLL_TO_DROP_SHIPPING_MERCHANT", scrollHandler);
    return () => {
      emitter.on("SCROLL_TO_DROP_SHIPPING_MERCHANT", scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/drop-shipping-merchant") {
      setTimeout(() => {
        scrollIntoView(el.current as HTMLDivElement);
      }, 20);
    }
  }, [location.pathname]);

  return (
    <div className="beyond-style-container">
      <div className="successful">
        <Image className="logo" src={Logo}></Image>
        <p className="title">Successful case</p>
        <div className="subtitle">
          <TextLine href="https://www.beyondstyle.us/">BeyondStyle</TextLine>
           serves as a digital personal shopping assistant. Over 1M fashion
          products can be easily discovered. Users could access real-time price
          information and get discounts and complete purchasing transaction
          through link out model.
        </div>
        <Button onClick={() => navigate("/contact-us")}>Contact Us</Button>
        <Image
          className="black-friday"
          src={isMobile ? blackFridayMobile : blackFriday}
        ></Image>
      </div>
      <div className="drop-shopping" ref={el}>
        <Image className="drop-shopping-img" src={dropShipping}></Image>
        <div>
          <div className="drop-shopping-title">
            If you are a <br />
            Drop-shipping <br />
            Merchant
          </div>
          <div className="des">
            <p>
              Expand your business to fashion and luxury categories by
              ready-to-go solution.
            </p>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="product-category">
          <div>
            Increase your revenue and expand your product category to include
            global fashion and beauty.
          </div>
        </div>
      )}
      <div className="product-list-wrapper">
        {list.map((item, index) => (
          <div className="product-list-row" key={index}>
            <Parallax
              translateX={item.translateX}
              className="product-list-transform"
            >
              {item.list.map((v, k) => (
                <Image className="product-list-img" src={v} key={k}></Image>
              ))}
            </Parallax>
          </div>
        ))}
      </div>
      {isMobile && (
        <div className="product-category">
          <div>
            Increase your revenue and expand your product category to include
            global fashion and beauty.
          </div>
        </div>
      )}
      <div className="top-fashion">
        <div className="des">
          <p>
            Win your premium customer by offering luxury and top fashion brands
            from Saks Fifth Avenue, Harrods, 24s, Michael Kors, etc.
          </p>
          <p>
            Work with reliable and trustworthy suppliers from all over the world supported by Cloud Store.
          </p>
        </div>
        <Image className="top-fashion-img" src={topFashion}></Image>
      </div>
      <div className="better-drop-shipping">
        <Image
          className="better-drop-shipping-img"
          src={betterDropShipping}
        ></Image>
        <p className="des">
          Better drop shipping solution through end-to-end global logistics
          tracking.
        </p>
      </div>
      <div className="successful-wrapper">
        <div className="successful-main">
          <div className="successful-title">
            Successful {isMobile && <br />}Cases
          </div>
          <div className="successful-title">
            One Shopify store {isMobile && <br />}
            <TextLine>doubled its sales</TextLine>
          </div>
          <div className="successful-title">
            in the second month after integrating Cloud Store API
          </div>
          <Image src={sales} className="sales"></Image>
        </div>
      </div>
    </div>
  );
}

export default BeyondStyle;