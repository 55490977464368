import mitt from "mitt";

export type Events = {
  SCROLL_TO_MERCHANT: void;
  SCROLL_TO_E_COMMERCE_MARKETPLACE: void;
  SCROLL_TO_INFLUENCER_PUBLISHER: void;
  SCROLL_TO_DROP_SHIPPING_MERCHANT: void;
  SCROLL_TO_BRAND_LAUNCH: void
};

const emitter = mitt<Events>();

export default emitter;
