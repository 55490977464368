import { useEffect } from 'react'
import { Image, FadeTranstion } from '@/components'
import { useMobile } from '@/hooks'
import borderX_Lab from '@/assets/images/borderX-Lab.png'
import logisticsPlatform from "@/assets/images/logistics-platform.png"
import companyCulture from '@/assets/images/company-culture.png'
import investors1 from '@/assets/images/investors-1.png'
import investors2 from "@/assets/images/investors-2.png";
import news from '@/assets/images/news.png'

import './style.scss'
import "./style.mobile.scss"

const Company = () => {
  const [ isMobile ] = useMobile();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="company-page">
      <section className="company-module boxder-x">
        <FadeTranstion className="company-module-text-wrapper">
          <h4 className="company-module-text border-x">
            In 2014, three engineering Ph.D.s from Google started BorderX Lab in
            Silicon Valley, California with one mission – make a safe and
            convenient platform for shoppers worldwide to buy authentic products
            at a fair price.
          </h4>
        </FadeTranstion>
        <FadeTranstion className="border-x-img-wrapper">
          <Image className="border-x-img" src={borderX_Lab}></Image>
        </FadeTranstion>
      </section>
      <section className="company-module logistics-platform">
        <FadeTranstion>
          <Image className="logistics-platform-img" src={logisticsPlatform} />
        </FadeTranstion>
        <FadeTranstion>
          <h4 className="company-module-text logistics-platform">
            BorderX Lab has developed an innovative personalization and
            logistics platform to create the world's best cross-border shopping
            experience. {isMobile && <br />} Cloud Store product is one of the
            products created by BorderX Lab, which to empower the global
            e-commerce simply.
          </h4>
        </FadeTranstion>
      </section>
      <section className="company-culture">
        <FadeTranstion>
          <Image className="company-culture-img" src={companyCulture}></Image>
        </FadeTranstion>
        <FadeTranstion>
          <h4 className="company-module-text company-culture">
            At BorderX Lab, we have a common purpose that drives our company
            culture. We are committed to making cross-border e-commerce work for
            merchants and shoppers to bring the US and European lifestyle to the
            world. We firmly believe that our holistic solutions to cross-border
            shopping, relentless pursuit of customer satisfaction, data-driven
            process, aspiration for innovation and atmosphere of constant
            creation will help us grow and thrive!
          </h4>
        </FadeTranstion>
      </section>
      <FadeTranstion className="news-investors">
        <h4 className="news-investors-title">News and Investors</h4>
        <div className="img-wrapper">
          <Image className="news-img" src={news}></Image>
          <div className="investors">
            <Image className="investors-1" src={investors1}></Image>
            <Image className="investors-2" src={investors2}></Image>
          </div>
        </div>
        <a
          className="investors-link"
          href="https://www.borderxlab.com/post/borderx-lab-raises-20m-in-series-b-funding-round-led-by-kleiner-perkins-2"
          target="_blank"
          rel="noreferrer"
        >
          BorderX Lab Raises $20m in Series B Funding Round Led by Kleiner
          Perkins
        </a>
      </FadeTranstion>
    </div>
  );
}

export default Company;