import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

type Parameters = Partial<{ [key: string]: string | Array<string> }>

export default function useQuery() {
  const { search } = useLocation();

  return useMemo(() => {
    return search
      .slice(1)
      .split("&")
      .reduce<Parameters>((initiVal, item) => {
        const [key, value] = item.split("=");
        if (key && value) {
          const decodedValue = decodeURIComponent(value);
          if (initiVal.hasOwnProperty(key)) {
            const preValue = initiVal[key];
            if (Array.isArray(preValue)) {
              preValue.push(decodedValue);
            } else if (typeof preValue === "string") {
              initiVal[key] = [preValue];
            }
          } else {
            initiVal[key] = decodedValue;
          }
        }
        return initiVal;
      }, {});
  }, [search]);
}