import { FC, CSSProperties, useState } from "react";
import { getImageUrl } from '@/utils/helper'
import { Parallax } from 'react-scroll-parallax'
const rootMargin = {
  top: 300,
  bottom: 300,
  left: 300,
  right: 300,
}
interface ImageProps {
  className?: string;
  style?: CSSProperties;
  alt?: string
  src?: string
}

const Image: FC<ImageProps> = ({ src, className, alt }) => {
  const [visible, setVisible] = useState(false);
  return visible ? (
    <img className={className} src={getImageUrl(src)} alt={alt} />
  ) : (
    <Parallax
      rootMargin={rootMargin}
      className={className}
      onEnter={() => setVisible(true)}
    />
  );
};

export default Image; 