import { createContext, useContext, FC, PropsWithChildren, useEffect, useRef, RefObject } from 'react'
import { useLocation } from 'react-router-dom'

export interface ScrollHandler {
  (e: ScrollEvent): void
}

export type ScrollType = "scroll" | 'resize' | 'init'

export const scrollContext = createContext<{
  scrollHandlers: RefObject<ScrollHandler[]>;
}>({ scrollHandlers : { current: [] }});

export function useScroll() {
  return useContext(scrollContext);
}

export interface ScrollEvent {
  scrollTop: number;
  type: ScrollType;
  direction?: "top" | 'bottom';
}

export const getSrollEvent = (type: ScrollType): ScrollEvent => {
  const scrollTop =
    window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
  return {
    scrollTop,
    type,
  };
};

export const ScrollProvider: FC<PropsWithChildren> = ({children}) => {

  const location = useLocation();

  const scrollHandlers = useRef<ScrollHandler[]>([]);
  const scorllY = useRef(0)
  useEffect(() => {
      scorllY.current = document.documentElement.offsetHeight;
  }, [location.pathname]);

  useEffect(() => {
    const scrollHandler = (e: Event) => {
      let direction: ScrollEvent['direction']
      const event = getSrollEvent(e.type as ScrollType)
      if (event.type === "scroll" || event.type === "resize"){
        if(event.scrollTop > scorllY.current) {
          direction = 'bottom'
        }else {
          direction = "top";
        }
        scorllY.current = event.scrollTop
        event.direction = direction;
      }
      scrollHandlers.current.forEach((handler) => handler(event));
    };
    window.addEventListener("scroll", scrollHandler);
    window.addEventListener("resize", scrollHandler);
    return () => {
      scrollHandlers.current = []
      scorllY.current = 0
      window.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("resize", scrollHandler);
    }
  }, []);

  return (
    <scrollContext.Provider value={{ scrollHandlers }}>
      {children}
    </scrollContext.Provider>
  );
}