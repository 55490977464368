import './style.scss'
import './style.mobile.scss'
import _Tabs from "./tabs";
import TabPane from "./tab-pane";

const Tabs = Object.assign(_Tabs, { TabPane });

export default Tabs;

export { Tabs };
