import { createContext, useContext, FC, PropsWithChildren } from 'react'

interface ITabsContext {
  active: string
}

const TabsContext = createContext<string>('');


export const TabsProvider: FC<PropsWithChildren<ITabsContext>> = ({ children, active }) => {
  return <TabsContext.Provider value={active}>{children}</TabsContext.Provider>;
};

export const useTabs = () => useContext(TabsContext)