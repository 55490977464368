export const apiOptions = [
  {
    label: "Catalog API",
    value: "Catalog API",
  },
  {
    label: "Checkout API",
    value: "Checkout API",
  },
  {
    label: "Logistic API",
    value: "Logistic API",
  },
  {
    label: "End-to-end solution",
    value: "End-to-end solution",
  },

  {
    label: "Merchant ecosystem",
    value: "Merchant ecosystem",
  },
  {
    label: "Others",
    value: "Others",
  },
];


export const brandsApiOptions = [
  {
    label: "BeyondStyle",
    value: "BeyondStyle",
  },
  {
    label: "Walmart",
    value: "Walmart",
  },
  {
    label: "Google Shopping",
    value: "Google Shopping",
  },
  {
    label: "Google Search CPC Ads",
    value: "Google Search CPC Ads",
  },

  {
    label: "Pinterest",
    value: "Pinterest",
  },
  {
    label: "Tiktok",
    value: "Tiktok",
  },
  {
    label: "SheIn",
    value: "SheIn",
  },
  {
    label: "Affiliate CPA Marketing",
    value: "Affiliate CPA Marketing",
  },
  {
    label: "Warehouse and fulfillment service in the USA",
    value: "Warehouse and fulfillment service in the USA",
  },
  {
    label: "Brand Trademark Application in the USA",
    value: "Brand Trademark Application in the USA",
  },
];



export const businessOptions = [
  {
    label: "marketplace(APP)",
    value: "marketplace(APP)",
  },
  {
    label: "marketplace(Website)",
    value: "marketplace(Website)",
  },
  {
    label: "publisher",
    value: "publisher",
  },
  {
    label: "shopifyowner",
    value: "shopifyowner",
  },
  {
    label: "merchants",
    value: "merchants",
  },
  {
    label: "brands",
    value: "brands",
  },
  {
    label: "others",
    value: "others",
  },
];

export const  dailyOrdersOptions = [
  {
    label: "1-50",
    value: "1-50",
  },
  {
    label: "50-200",
    value: "50-200",
  },
  {
    label: "200-500",
    value: "200-500",
  },
  {
    label: "500-1000",
    value: "500-1000",
  },
  {
    label: "1000+",
    value: "1000+",
  },
];

export const productsOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const cloudStoreOptions = [
  {
    label: "Fashion",
    value: "Fashion",
  },
  {
    label: "Sports",
    value: "Sports",
  },
  {
    label: "Beauty",
    value: "Beauty",
  },
  {
    label: "Health Care",
    value: "Health Care",
  },
  {
    label: "Mom & Baby",
    value: "Mom & Baby",
  },
  {
    label: "Home",
    value: "Home",
  },
  {
    label: "Food",
    value: "Food",
  },
  {
    label: "Electronics",
    value: "Electronics",
  },
  {
    label: "Others",
    value: "Others",
  },
];