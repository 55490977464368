import { Parallax } from "react-scroll-parallax"
import {TextLine, Image} from '@/components'
import { useMobile } from '@/hooks'
import { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom'
import { scrollIntoView } from '@/utils'
import emitter from "@/event";
import curate from "@/assets/images/curate-mobile.png";
import logo1 from "@/assets/images/logo-1.png";
import logo2 from "@/assets/images/logo-2.png";
import publisherProduct from '@/assets/images/publisher-product.png'

import by_s_pd from '@/assets/images/by-s-pd.png'
import by_s_h_b from '@/assets/images/by-s-h-b.png'

import price_history_detail from "@/assets/images/price-history-detail.png";
import price_history_list_page from "@/assets/images/price-history-list-page.png";

import './style.scss'
import "./style.mobile.scss"

const Publisher = () => {
  const [ isMobile ] = useMobile();
  const location = useLocation();
  const el = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollHandler = () => {
      scrollIntoView(el.current as HTMLDivElement);
    };
    emitter.on("SCROLL_TO_INFLUENCER_PUBLISHER", scrollHandler);
    return () => {
      emitter.on("SCROLL_TO_INFLUENCER_PUBLISHER", scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/influencer-publisher") {
      setTimeout(() => {
        scrollIntoView(el.current as HTMLDivElement);
      }, 20);
    }
  }, [location.pathname]);

  return (
    <div className="publisher-container" ref={el}>
      <div className="block">
        {isMobile ? (
          <>
            <div className="title">If you are an Influencer or Publisher</div>
            <p className="subtitle">
              Transform your business <br /> through embedded eCommerce <br />{" "}
              solution.  
            </p>
          </>
        ) : (
          <>
            <div className="title">If you are an</div>
            <TextLine className="title">Influencer or Publisher</TextLine>
            <p className="subtitle">
              Transform your business through 
              <br />
              embedded eCommerce solution.
            </p>
          </>
        )}
        <div className="row curate">
          <Image className="curate-img" src={curate}></Image>
          <p className="row-text">
            Curate your product collection with real time price, checkout
            function with our one stop solution.
          </p>
        </div>
        {!isMobile && (
          <p className="subtitle connections">
            Gain access to ready to use network of famous shopping mall and
            retailer connections
          </p>
        )}
        <Image className="logo-1" src={logo1}></Image>
        <Image className="logo-2" src={logo2}></Image>
        <div className="publisher-product-container">
          <Parallax speed={isMobile ? -6 : -12}>
            <Image
              src={publisherProduct}
              className="publisher-product"
            / >
          </Parallax>
        </div>
        {isMobile && (
          <p className="subtitle connections">
            Gain access to ready to use network of famous shopping mall and
            retailer connections
          </p>
        )}
        <div className="row revenue">
          <p className="row-text">
            Increase your revenue by offering check out options and exclusive
            deals and discounts.
          </p>
          <Image className="by-s-h image" src={by_s_h_b}></Image>
          <Image className="by-s-pd image" src={by_s_pd}></Image>
        </div>

        <div className="row comparison">
          <Image
            className="history-detail image"
            src={price_history_detail}
          ></Image>
          <Image
            className="history-list image"
            src={price_history_list_page}
          ></Image>
          <p className="row-text">
            Centralized product research and price comparison process.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Publisher;