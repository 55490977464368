import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Input, Checkbox, Select, Button } from '@/components'
import {
  apiOptions,
  businessOptions,
  dailyOrdersOptions,
  productsOptions,
  cloudStoreOptions,
  brandsApiOptions
} from "@/constant";
import { useMobile, useQuery } from '@/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import "./style.scss"
import "./style.mobile.scss"
import toast from 'react-hot-toast'
import { Tabs } from "@/components"
import classNames from "classnames";
import loadingIcon from "@/assets/images/loading.svg"
import throttle from "@/utils/throttle";
enum PartnerType {
  MarketPlacePublisher = "MarketPlacePublisher",
  BrandsMerchants = "BrandsMerchants",
}

const ContactUs = () => {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const navigate = useNavigate();
  const { type } = useQuery();
  const [active, setActive] = useState(() => {
    return typeof type === "string" &&
      (type === PartnerType.BrandsMerchants ||
        type === PartnerType.MarketPlacePublisher)
      ? type
      : PartnerType.MarketPlacePublisher;
  });
  const [touch, setTouch] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState({
    name: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    companyRole: "",
    businessType: "",
    exploreSolution: [],
    offerCategory: [],
    integrateCategory: [],
    dailyOrders: "",
    needUsWarehouse: "",
    salesRegion: "",
    deliveryCountry: "",
  });

  const [status, setStatus] = useState<
    Partial<Record<keyof typeof data, boolean>>
  >({});

  const [ isMobile ] = useMobile()

  const handleChange = (field: keyof typeof data , value: string | string[]) => {
    if (Array.isArray(value)) {
      status[field] = !value.length;
    } else {
      status[field] = !value;
    }
    setData((pre) => ({
      ...pre,
      [field]: value,
    }));
  }

  const omitFields = () => {
    type Keys = keyof typeof data
    type Fields = Partial<Record<Keys, boolean>>;
    const MarketPlacePublisherFields: Fields = {
      integrateCategory: true,
      needUsWarehouse: true,
    };
    const BrandsMerchantsFields: Fields = {
      offerCategory: true,
      salesRegion: true,
      deliveryCountry: true,
    };
    return (Object.keys(data) as Keys[]).filter((field) => {
      if (
        active === PartnerType.MarketPlacePublisher &&
        BrandsMerchantsFields[field]
      ) {
        return false;
      }
      if (
        active === PartnerType.BrandsMerchants &&
        MarketPlacePublisherFields[field]
      ) {
        return false
      }
      return true;
    });
  }

  const validate = () => {
    let pass = true
    let state: typeof status = {};
    omitFields().forEach((field) => {
      let value = data[field];
      if (Array.isArray(value)) {
        state[field] = !value.length;
      } else {
        state[field] = !value;
      }
      if (state[field]) {
        pass = false;
      }
    });
    setStatus(state);
    return pass
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitHandler = useCallback(throttle(2000, true, (formData: Record<string, string | string[]>) => {
    toast.remove();
    setLoading(true);
    axios
      .post(
        "https://cloudstore.bybieyang.com/cloud-store/partner/submit",
        formData,
        {
          timeout: 60000,
        }
      )
      .then(() => {
        toast.custom(() => {
          return <div className="toast-container">Submitted</div>;
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [])

  const onSubmit = () => {
    if (loading) {
      return;
    }
    if (!validate()) {
      return;
    }
    const apiData = omitFields().reduce(
      (init, item) => {
        init[item] = data[item];
        return init;
      },
      { partnerType: active } as Record<string, string | string[]>
    );
    submitHandler(apiData);
  }

  const renderdailyOrdersTitle = () => {
    return (
      <div className="line-height-28">
        What's your expected orders {isMobile ? <br /> : null}per day after
        integration?
      </div>
    );
  }

  const renderDeliveryCountryTitle = () => {
    return (
      <div className="line-height-28">
        Which country could you deliver the{isMobile ? <br /> : null} products?
      </div>
    );
  }

  const renderLogistic = () => {
    return (
      <div className="line-height-28">
        Do you need a US warehouse/{isMobile ? <br /> : null}logistic solution
        to deliver the{isMobile ? <br /> : null} products?
      </div>
    );
  }

  const rendersalesRegion = () => {
    return (
      <div className="line-height-28">
        Which country or area would you{isMobile ? <br /> : null} like to sell
        your products?
      </div>
    );
  };

  const onMouseDown = () => {
    setTouch(true);
    window.removeEventListener("mouseup", onMouseUp);
    window.removeEventListener("touchend", onMouseUp);
    window.addEventListener("mouseup", onMouseUp, { once: true });
    window.addEventListener("touchend", onMouseUp, { once: true });
  };

  const onMouseUp = useCallback(() => {
    setTouch(false);
  }, [])

  const onTabChange = (e: string) => {
    setActive(e as PartnerType);
    handleChange("exploreSolution", '');
    setStatus({});
    navigate({
      ...location,
      search: `type=${e}`,
    }, { replace: true })
  };

  return (
    <section className="contact-us-page">
      <div className="module-title">
        <h2 className="contact-us-title">Partner with Cloud Store</h2>
        <p className="contact-us-subtitle">
          We will contact you within one business day after submission. 
        </p>
      </div>
      <div className="contact-us-content">
        <Tabs active={active} onChange={onTabChange}>
          <Tabs.TabPane
            tab={PartnerType.MarketPlacePublisher}
            title={
              <div className="marketplaces-publishers-title">
                Marketplaces & Publishers
              </div>
            }
          />
          <Tabs.TabPane
            tab={PartnerType.BrandsMerchants}
            title={
              <div className="brands-merchants-title">Brands & Merchants</div>
            }
          />
        </Tabs>
        <div className={classNames("module-form", [active.toLowerCase()])}>
          <Input
            className="form-item"
            label="Name"
            value={data.name}
            onChange={(e) => handleChange("name", e.target.value)}
            error={status.name}
          />
          <Input
            className="form-item"
            label="Email"
            value={data.email}
            error={status.email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
          <Input
            className="form-item"
            label="Company name"
            value={data.companyName}
            error={status.companyName}
            onChange={(e) => handleChange("companyName", e.target.value)}
          />
          <Input
            className="form-item"
            label="Phone Number"
            value={data.phoneNumber}
            error={status.phoneNumber}
            onChange={(e) => handleChange("phoneNumber", e.target.value)}
          />
          <Input
            className="form-item company-role"
            label="Your role at the company"
            value={data.companyRole}
            error={status.companyRole}
            onChange={(e) => handleChange("companyRole", e.target.value)}
          />
          <Select
            className="business-type form-item"
            label="Business type"
            options={businessOptions}
            value={data.businessType}
            error={status.businessType}
            onChange={(e) => handleChange("businessType", e)}
          />
          {active === PartnerType.BrandsMerchants ? (
            <>
              <Checkbox
                className="form-item brands-merchansts"
                label="Which solution would you like to explore more?"
                value={data.exploreSolution}
                error={status.exploreSolution}
                onChange={(e) => handleChange("exploreSolution", e)}
              >
                {brandsApiOptions.map((item, index) => (
                  <Checkbox.Option
                    className={`option-item-${Math.floor(index / 5)}`}
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </Checkbox.Option>
                ))}
              </Checkbox>
              <Checkbox
                className="form-item cloud-stores"
                label="What category do you offer for cloud stores?"
                value={data.offerCategory}
                error={status.offerCategory}
                onChange={(e) => handleChange("offerCategory", e)}
              >
                {cloudStoreOptions.map((item, index) => (
                  <Checkbox.Option
                    className={`option-item-${Math.floor(index / 3)}`}
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </Checkbox.Option>
                ))}
              </Checkbox>
            </>
          ) : (
            <>
              <Checkbox
                className="form-item explore"
                label="Which solution would you like to explore more?"
                value={data.exploreSolution}
                error={status.exploreSolution}
                onChange={(e) => handleChange("exploreSolution", e)}
              >
                {apiOptions.map((item, index) => (
                  <Checkbox.Option
                    className={`option-item-${Math.floor(index / 3)}`}
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </Checkbox.Option>
                ))}
              </Checkbox>
              <Checkbox
                className="form-item cloud-stores"
                label="Which category would you like to integrate?"
                value={data.integrateCategory}
                error={status.integrateCategory}
                onChange={(e) => handleChange("integrateCategory", e)}
              >
                {cloudStoreOptions.map((item, index) => (
                  <Checkbox.Option
                    className={`option-item-${Math.floor(index / 3)}`}
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </Checkbox.Option>
                ))}
              </Checkbox>
            </>
          )}
          <Select
            className="revenue form-item"
            label={renderdailyOrdersTitle()}
            options={dailyOrdersOptions}
            value={data.dailyOrders}
            error={status.dailyOrders}
            onChange={(e) => handleChange("dailyOrders", e)}
          />
          {active === PartnerType.MarketPlacePublisher && (
            <Select
              options={productsOptions}
              className="products form-item"
              label={renderLogistic()}
              value={data.needUsWarehouse}
              error={status.needUsWarehouse}
              onChange={(e) => handleChange("needUsWarehouse", e)}
            />
          )}
          {active === PartnerType.BrandsMerchants && (
            <>
              <Input
                className="form-item sell-products"
                label={rendersalesRegion()}
                value={data.salesRegion}
                error={status.salesRegion}
                onChange={(e) => handleChange("salesRegion", e.target.value)}
              />
              <Input
                className="form-item deliver-products"
                label={renderDeliveryCountryTitle()}
                value={data.deliveryCountry}
                error={status.deliveryCountry}
                onChange={(e) =>
                  handleChange("deliveryCountry", e.target.value)
                }
              />
            </>
          )}
          <Button
            onTouchStart={onMouseDown}
            onMouseDown={onMouseDown}
            className={classNames("action-button", { touch, loading })}
            icon={false}
            onClick={onSubmit}
          >
            Submit Enquiry {loading && <img src={loadingIcon} alt="loading" />}
          </Button>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;