export const isSupportWebp = () => {
  try {
    const img = document.createElement("canvas");
    const imgData = img.toDataURL("image/webp", 0.5);
    return imgData.indexOf("data:image/webp") === 0;
  } catch (err) {
    return false;
  }
};

const isSupport = isSupportWebp();

export function getImageUrl(
  url: string | undefined | null,
  styleName?: string
): string {
  if (!url) {
    return "";
  }
  if (!isSupport) {
    return url;
  }
  if (url.startsWith("http://")) {
    url = url.replace("http://", "https://");
  }
  // if image url has any query params, assume that the image will override the oss params
  if (
    /(beyondstyle\.us|bybieyang\.com|bieyangapp\.com)\/.*\.(png|jpg|jpeg)$/i.test(
      url
    )
  ) {
    // by default s1 -> webp + 90q
    return url + `!${styleName || "s1"}`;
  }
  return url;
}
