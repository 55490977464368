import { Image, FadeTranstion } from "@/components"
import EmpowermentImg from "@/assets/images/empowerment.png";
import './style.scss'
import "./style.mobile.scss"

const Empowerment = () => {
  return (
    <section className="empowerment">
      <FadeTranstion className="description">
        <h4 className="description-title text-title">
          Dynamic E-commerce Empowerment
        </h4>
        <p className="text-content">
          Revolutionize your e-commerce capability with our embedded solutions.
          Our technology seamlessly integrates with your existing
          infrastructure, ensuring a smooth transition and enhancing the overall
          performance.
        </p>
      </FadeTranstion>
      <FadeTranstion>
        <Image className="empowerment-img" src={EmpowermentImg}></Image>
      </FadeTranstion>
    </section>
  );
}

export default Empowerment;