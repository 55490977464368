import {
  useMemo,
  FC,
  PropsWithChildren,
  Children,
  isValidElement,
  ReactElement,
  ReactNode,
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
  useEffect
} from "react";
import { TabsProvider } from './context'
import TabPane, { TabPaneProps } from "./tab-pane";

interface TableProps {
  active?: string
  defaultKey?: string
  onChange?: (current: string) => void
}

const Table: FC<PropsWithChildren<TableProps>> = ({ active, onChange, defaultKey, children }) => {

  const [validateChildren, headers] = useMemo(() => {
    const list: ReactElement<TabPaneProps>[] = [];
    const headers: { tab: string; render: ReactNode }[] = [];
    
    Children.forEach(children, (child) => {
      if (isValidElement(child) && child.type === TabPane) {
        list.push(child as ReactElement<TabPaneProps>);
        headers.push({
          tab: child.props.tab as string,
          render: child.props.title,
        });
      }
    });
   
    return [list, headers];
  }, [children]);

  const titleEls = useRef<Record<string, HTMLLIElement>>({})

  const [current, setCurrent] = useState(() => {
    if(active) {
      return active
    }else if(defaultKey) {
      return defaultKey
    }else {
      return headers[0]?.tab
    }
  })

  const latestCurrent = useRef(current)
  latestCurrent.current = current

  useLayoutEffect(() => {
    active && setCurrent(active)
  }, [active])
  

  const onUpdateActve = (key: string) => {
    if(!active && !onChange) {
      setCurrent(key)
    }else {
      onChange?.(key)
    }
  }

  const [position, setPosition] = useState({ left: 0, width: 0 })

  const updateMaskPosition = useCallback((current: string) => {
     const currentEl = titleEls.current[current];
     if (currentEl) {
       setPosition({
         left: currentEl.offsetLeft,
         width: currentEl.offsetWidth,
       });
     }
  }, []);

  useLayoutEffect(() => {
    updateMaskPosition(current);
  }, [current, updateMaskPosition]);

  useEffect(() => {
    const handler = () => {
      updateMaskPosition(latestCurrent.current);
    };
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    }
  }, [updateMaskPosition]);
  
  return (
    <TabsProvider active={current}>
      <div className="tabs-container">
        <ul className="tabs-title-group">
          {headers.map((header) => (
            <li
              ref={(el) => (titleEls.current[header.tab] = el as HTMLLIElement)}
              className={`tabs-title-item ${
                current === header.tab ? "active" : ""
              }`}
              onClick={() => onUpdateActve(header.tab)}
              key={header.tab}
            >
              {header.render}
            </li>
          ))}
          <li
            style={{
              width: position.width,
              transform: `translateX(${position.left}px)`,
            }}
            className="tabs-title-mark"
          ></li>
        </ul>
        <div className="tabs-pane-group">{validateChildren}</div>
      </div>
    </TabsProvider>
  ); 
};


export default Table;