import { FC, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames';
import { Button } from '@/components'
import { useScroll, useMobile } from "@/hooks";
import emitter, { Events } from '@/event';
import { Portal } from "react-portal";
import "./style.scss";
import "./style.mobile.scss"


interface LinkType {
  path: string,
  label: string
  event?: keyof Events
}

const MOBILE_LINKS: LinkType[] = [
  {
    path: "/e-commerce-marketplace",
    label: "E-commerce Marketplace",
    event: "SCROLL_TO_E_COMMERCE_MARKETPLACE",
  },
  {
    path: "/influencer-publisher",
    label: "Influencer And Publisher",
    event: "SCROLL_TO_INFLUENCER_PUBLISHER",
  },
  {
    path: "/drop-shipping-merchant",
    label: "Drop-shipping Merchant",
    event: "SCROLL_TO_DROP_SHIPPING_MERCHANT",
  },
  {
    path: "/merchants-brands",
    label: "Brands",
    event: "SCROLL_TO_MERCHANT",
  },
  {
    path: "/brand-launch",
    label: "BrandsLaunch",
    event: "SCROLL_TO_BRAND_LAUNCH",
  },
  {
    path: "/company",
    label: "Company",
  },
];


type Theme = "dark" | "light" | "hoary";

const Header: FC = () => {
  const headerRef = useRef<HTMLHeadElement>(null);
  const [fixed, setFixed] = useState(false);
  const [show, setShow] = useState(false);
  const location = useLocation()
  const [isMobile] = useMobile();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const [ visible, setVisible ] = useState({
    solution: false,
    brands: false
  }) 

  useScroll((e) => {
    if(e.scrollTop > 0) {
      setFixed(true)
      if(e.direction === "top") {
        setShow(true)
      } else {
        setShow(false);
        setVisibleHandler({ solution: false, brands: false })
      }
    } else {
      setFixed(false)
    }
  })

  const getTheme = (): Theme => {
    switch (location.pathname) {
      case "/":
      case "/e-commerce-marketplace":
      case "/influencer-publisher":
      case "/drop-shipping-merchant":
      case "/merchants-brands":
        return "hoary";
      case "/company":
        return "dark";
      default:
        return "light";
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 100)
    return () => {
      clearTimeout(timer)
    }
  }, [location.pathname])

  const theme = getTheme();

  const onNavigate = (link: LinkType) => {
    navigate(link.path)
    setOpen(false)
  }

  const setVisibleHandler = (data: Partial<typeof visible>) => {
     setVisible((pre) => ({ ...pre, ...data }));
  }

  const backHome = () => {
    window.scroll(0, 0)
  }

  return (
    <>
      <header ref={headerRef} className={classNames("page-header", theme)}>
        <div className={classNames("header-content", { fixed, show })}>
          <a href="#/" onClick={backHome}>
            <h1 className="title">Cloud Store</h1>
          </a>
          <ul className="link-group">
            {!isMobile && (
              <>
                <li
                  className={classNames("link-item anchor")}
                  onMouseEnter={() => setVisibleHandler({ solution: true })}
                  onMouseLeave={() => setVisibleHandler({ solution: false })}
                >
                  <span className="link-item-text">Solution</span>
                  <div
                    className={classNames("anchor-group", {
                      show: visible.solution,
                    })}
                  >
                    {MOBILE_LINKS.slice(0, 3).map((item) => (
                      <Link
                        onClick={() => {
                          setVisibleHandler({ solution: false });
                          item.event && emitter.emit(item.event);
                        }}
                        to={item.path}
                        key={item.path}
                      >
                        <div className="link-inner">
                          <span>{item.label}</span>
                        </div>
                      </Link>
                    ))}
                  </div>
                </li>

                <li
                  className={classNames("link-item", "brands")}
                  onMouseEnter={() => setVisibleHandler({ brands: true })}
                  onMouseLeave={() => setVisibleHandler({ brands: false })}
                >
                  <Link
                    to="/merchants-brands"
                    onClick={() => emitter.emit("SCROLL_TO_MERCHANT")}
                  >
                    <span className="link-item-text">Brands</span>
                  </Link>
                  <div
                    className={classNames("anchor-group", {
                      show: visible.brands,
                    })}
                  >
                    <Link
                      onClick={(e) => {
                        setVisibleHandler({ brands: false });
                        emitter.emit("SCROLL_TO_BRAND_LAUNCH");
                      }}
                      to="/brand-launch"
                    >
                      <div className="link-inner">
                        <span>BrandLaunch</span>
                      </div>
                    </Link>
                  </div>
                </li>
                <li className="link-item">
                  <Link to="/company">
                    <span
                      className={classNames("link-item-text", {
                        active: location.pathname === "/company",
                      })}
                    >
                      Company
                    </span>
                  </Link>
                </li>
              </>
            )}
            <li className="link-item">
              <Link className="contact-us" to="/contact-us">
                Contact Us
              </Link>
            </li>
            {isMobile && (
              <li className="link-item">
                <div className="open-icon" onClick={() => setOpen(true)}>
                  <span className="icon-line line-1"></span>
                  <span className="icon-line line-2"></span>
                  <span className="icon-line line-3"></span>
                </div>
              </li>
            )}
          </ul>
        </div>
      </header>
      {isMobile && (
        <Portal>
          <div className={classNames("aside-mask", { open })}></div>
          <div className={classNames("aside-content", { open })}>
            <div className="open-icon" onClick={() => setOpen(false)}>
              <span className="icon-line line-1"></span>
              <span className="icon-line line-3"></span>
            </div>
            <ul>
              {MOBILE_LINKS.map((item) => (
                <li className="link-item" key={item.path}>
                  <Link
                    to={item.path}
                    onClick={() => {
                      setOpen(false);
                      item.event && emitter.emit(item.event);
                    }}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
              <li className="link-item-action">
                <Button
                  onClick={() =>
                    onNavigate({
                      path: "/contact-us",
                      label: "",
                    })
                  }
                  icon={false}
                >
                  Contact Us
                </Button>
              </li>
            </ul>
          </div>
        </Portal>
      )}
    </>
  );
};

export default Header;